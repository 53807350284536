.Home {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.header__title {
  flex: 1;
}

.header__title > a,
.header__link > a {
  text-decoration: none;
  color: #fff;
}
