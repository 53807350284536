.Photos {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  max-height: calc(100vh - 191px);
}

.Photos__preview {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.Photos__preview > button {
  height: initial;
  border-radius: 0;
}

.Photos__preview > button > span {
  color: #fff;
}

.Photo {
  max-width: calc(100vw - 120px);
  display: flex;
}

.Photo > img {
  height: calc(100vh - 191px);
  margin: 0 auto;
}

.Photos__button {
  margin-bottom: 58px !important;
}

.Photo__action_bar {
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(0, 0, 0, .3);
  padding: 5px 0;
}

.Photo__action_bar > button,
.Photo__action_bar > div {
  margin: 0 10px;
}

.Photo__action_bar > * {
  color: #fff !important;
}
