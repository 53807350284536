.Proposal {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.Proposal__header {
  display: flex;
  justify-content: space-between;
  padding: 18px 2vw;
  background-color: #252525;
  max-height: 30px;
  min-height: 30px;
}

.Proposal__header > p {
  color: #fff;
  font-size: 18px;
}

.Proposal__content {
  display: flex;
  flex: 1 1 auto;
}

.Proposal__actions {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  background-color: #eee;
  border-top: 1px solid #bdbdbd;
}

.Proposal__actions > button {
  margin: 0 10px;
}

.Proposal__empty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.Proposal__empty_title {
  font-size: 20px;
  opacity: .5;
}
