body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #424242;
  color: #fafafa;
  width: 100%;
  height: 100vh;
}

.notify {
  display: flex;
  align-items: center;
}

.notify > * {
  padding-right: 5px;
}

.Photos {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  max-height: calc(100vh - 191px);
}

.Photos__preview {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.Photos__preview > button {
  height: auto;
  height: initial;
  border-radius: 0;
}

.Photos__preview > button > span {
  color: #fff;
}

.Photo {
  max-width: calc(100vw - 120px);
  display: flex;
}

.Photo > img {
  height: calc(100vh - 191px);
  margin: 0 auto;
}

.Photos__button {
  margin-bottom: 58px !important;
}

.Photo__action_bar {
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(0, 0, 0, .3);
  padding: 5px 0;
}

.Photo__action_bar > button,
.Photo__action_bar > div {
  margin: 0 10px;
}

.Photo__action_bar > * {
  color: #fff !important;
}

.Proposal {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.Proposal__header {
  display: flex;
  justify-content: space-between;
  padding: 18px 2vw;
  background-color: #252525;
  max-height: 30px;
  min-height: 30px;
}

.Proposal__header > p {
  color: #fff;
  font-size: 18px;
}

.Proposal__content {
  display: flex;
  flex: 1 1 auto;
}

.Proposal__actions {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  background-color: #eee;
  border-top: 1px solid #bdbdbd;
}

.Proposal__actions > button {
  margin: 0 10px;
}

.Proposal__empty {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}

.Proposal__empty_title {
  font-size: 20px;
  opacity: .5;
}

.Statistic {
  padding: 40px;
}

.Statistic__filters {
  margin-bottom: 40px;
  padding: 20px 40px;
  display: flex;
  height: 60px;
  align-items: center;
}

.Statistic__filters > form {
  display: flex;
  align-items: center;
}

.Statistic__address {
  width: 400px;
  margin-right: 10px !important;
}

.Statistic__dates {
  display: flex;
  align-items: flex-end;
  padding-bottom: 4px;
  height: 54px;
}

.Statistic__dates .picker {
  padding-right: 20px;
}

.statistic__photo {
  margin-right: 10px;
}

.Home {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.header__title {
  flex: 1 1;
}

.header__title > a,
.header__link > a {
  text-decoration: none;
  color: #fff;
}

.SignIn {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SignIn__form {
  width: 450px;
  height: auto;
}

