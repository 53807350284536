.SignIn {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SignIn__form {
  width: 450px;
  height: auto;
}
