body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #424242;
  color: #fafafa;
  width: 100%;
  height: 100vh;
}

.notify {
  display: flex;
  align-items: center;
}

.notify > * {
  padding-right: 5px;
}
