.Statistic {
  padding: 40px;
}

.Statistic__filters {
  margin-bottom: 40px;
  padding: 20px 40px;
  display: flex;
  height: 60px;
  align-items: center;
}

.Statistic__filters > form {
  display: flex;
  align-items: center;
}

.Statistic__address {
  width: 400px;
  margin-right: 10px !important;
}

.Statistic__dates {
  display: flex;
  align-items: flex-end;
  padding-bottom: 4px;
  height: 54px;
}

.Statistic__dates .picker {
  padding-right: 20px;
}

.statistic__photo {
  margin-right: 10px;
}
